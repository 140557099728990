import React from "react"
import Nav from "./nav"
import "./styles/header.scss"
import logo from "./img/logo_full.png"

export default ({ children }) => (
    <header>
        <h1>
            <img src={logo} alt="ShortOrme Productions" title="ShortOrme Productions" />
        </h1>
        <Nav />
    </header>
)